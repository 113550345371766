import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import SymposeumMobile from "../../images/symposeumMobile.png";
import SymposeumDT from "../../images/symposeum_logo.png";


/**
 * Navigation component
 *
 * The Navigation component takes an array of your Ghost
 * navigation property that is fetched from the settings.
 * It differentiates between absolute (external) and relative link (internal).
 * You can pass it a custom classNameName for your own styles, but it will always fallback
 * to a `site-nav-item` classNameName.
 *
 */

const NavDesktopItem = ({ copy, link, onClick, classes }) => {
    return (
        <div
        className={`${classes || ' '} nav-desktop-item font-sans font-light`}
    >
        <a
            onClick={() => onClick ? onClick() : navigate(link)}
            href="#"
            class="ml-8 px-3 py-2 rounded-md text-md leading-5 font-medium text-gray-400 hover:text-gray-500 focus:outline-none transition duration-150 ease-in-out transition duration-150 ease-in-out"
            style={{ color: "#202020", fontWeight: 300, fontSize: 16, letterSpacing: 1 }}
        >
            {copy}
        </a>
    </div>
    )
}

const Modal = ({ setShowSignUp, showSignUp }) => {
    const [email, setEmail] = useState("");

    function useOutsideAlerterSign(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowSignUp(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const signUpRef = useRef(null);
    useOutsideAlerterSign(signUpRef);

    return (
        <div
            class={`fixed inset-0 overflow-y-auto items-center justify-center ${
                showSignUp ? "visible" : "invisible"
            }`}
            style={{zIndex: 500}}
        >
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                {/* <!--
          Background overlay, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        --> */}
                <div
                    class={`fixed inset-0 transition-opacity ${
                        showSignUp ? "opacity-100" : "opacity-0"
                    } ease-in duration-200`}
                >
                    <div class="absolute inset-0 bg-white opacity-75"></div>
                </div>
                {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
                &#8203;
                {/* <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        --> */}
                <div
                    ref={signUpRef}
                    class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div>
                        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full">
                            {/* <!-- Heroicon name: check --> */}
                            {/* <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg> */}
                            <img src={SymposeumMobile} />
                        </div>
                        <div class="mt-3 text-center sm:mt-5">
                            <h3
                                class="text-xl3 leading-6 font-medium font-serif text-gray-900"
                                id="modal-headline"
                                style={{ marginTop: "1.5rem" }}
                            >
                                Sign up for our newsletter
                            </h3>

                            {/* <span class="text-xl2 font-serif">Get the best of <i>Symposeum</i> delivered to your inbox.</span> */}
                            <span class="text-xl2 font-serif">
                                In print quarterly, in your inbox monthly.
                            </span>

                            <div id="mc_embed_signup">
                                <form
                                    onSubmit={async () => {
                                        await new Promise((r) =>
                                            setTimeout(r, 300)
                                        );
                                        setShowSignUp(false);
                                    }}
                                    action="https://gmail.us2.list-manage.com/subscribe/post?u=29dfec4fae1d92e0a332c9acd&amp;id=cda8a9e1ac"
                                    method="post"
                                    id="mc-embedded-subscribe-form"
                                    name="mc-embedded-subscribe-form"
                                    class="validate"
                                    target="_blank"
                                    novalidate
                                >
                                    <div id="mc_embed_signup_scroll">
                                        <div class="mc-field-group">
                                            <input
                                                type="email"
                                                value={email}
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                                name="EMAIL"
                                                class="text-lg required email appearance-none w-full px-5 py-3 border border-gray-300 text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out mt-6"
                                                id="mce-EMAIL"
                                                placeholder="Enter your email"
                                            />
                                        </div>
                                        <div id="mce-responses" class="clear">
                                            <div
                                                class="response"
                                                id="mce-error-response"
                                                style={{ display: "none" }}
                                            ></div>
                                            <div
                                                class="response"
                                                id="mce-success-response"
                                                style={{ display: "none" }}
                                            ></div>
                                        </div>
                                        <div
                                            style={{
                                                position: "absolute",
                                                left: "-5000px",
                                            }}
                                            aria-hidden="true"
                                        >
                                            <input
                                                type="text"
                                                name="b_29dfec4fae1d92e0a332c9acd_cda8a9e1ac"
                                                tabindex="-1"
                                                value=""
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            value="Subscribe"
                                            name="subscribe"
                                            id="mc-embedded-subscribe"
                                            class="text-lg w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out mt-8"
                                        >
                                            Sign up
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Navigation = ({ tags }) => {
    const [showMenu, setShowMenu] = useState(null);
    const [showSignUp, setShowSignUp] = useState(false);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowMenu(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const filtered = tags.nodes.filter((t) => t.slug.includes("issue"));

    const sorted = filtered.sort((a, b) =>
        b.slug.split("-").pop().localeCompare(a.slug.split("-").pop())
    );
    return (
        <>
            <nav
                className="bg-white nav"
                style={{ top: 0, position: "fixed", width: "100%", zIndex: 500 }}
            >
                <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 h-full">
                    <div className="relative flex justify-between h-full">
                        <div className="mobile-menu-button absolute inset-y-0 left-0 flex items-center">
                            {/* <!-- Mobile menu button --> */}
                            <button
                                className="xs:hidden inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none transition duration-150 ease-in-out max-w-7xl mx-auto px-2 sm:px-6 lg:px-8"
                                aria-label="Main menu"
                                aria-expanded="false"
                            >
                                <svg
                                    className="block h-10 w-10"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    onClick={(e) => {
                                        if (showMenu) return;
                                        e.preventDefault();
                                        setShowMenu(true);
                                    }}
                                    onMouseEnter={() => setShowMenu(true)}
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                                <svg
                                    className="hidden h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                            <div class="block" id="mobile-menu">
                                <div
                                    ref={wrapperRef}
                                    class="pt-2 pb-3 transition duration-150 ease-in-out"
                                    style={{
                                        textAlign: "left",
                                        width: "32rem",
                                        visibility: showMenu
                                            ? "visible"
                                            : "hidden",
                                        zIndex: -1,
                                        position: "fixed",
                                        top: 0,
                                        left: 0,
                                        height: "100vh",
                                    }}
                                >
                                    <div
                                        class="block px-4 py-2 text-base font-medium text-gray-500 text-gray-800 bg-white focus:outline-none focus:text-gray-800 focus:bg-white transition duration-150 ease-in-out rounded overflow-hidden shadow-lg"
                                        onMouseLeave={() => setShowMenu(false)}
                                        style={{ height: "100%" }}
                                    >
                                        <div style={{ height: "6rem" }}></div>
                                        <a
                                            href="/issues"
                                            class="block pl-4 pr-4 py-8 border-l-4 border-white-500 font-serif text-5xl focus:outline-none transition duration-150 ease-in-out"
                                            style={{
                                                fontWeight: "900",
                                                whiteWpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                color: "rgb(77, 77, 77)",
                                                fontSize: "4rem",
                                            }}
                                        >
                                            Issues
                                        </a>
                                        <ul
                                            style={{
                                                listStyle: "none",
                                                margin: 0,
                                                padding: 0,
                                            }}
                                        >
                                            {sorted.map((t) => {
                                                return (
                                                    <div>
                                                        <a
                                                            href={`/${t.slug}`}
                                                            class="ml-12 focus:outline-none transition duration-150 ease-in-out font-sans text-2xl"
                                                            style={{
                                                                whiteSpace:
                                                                    "nowrap",
                                                                textOverflow:
                                                                    "ellipsis",
                                                                color:
                                                                    "rgb(77, 77, 77)",
                                                                cursor:
                                                                    "pointer",
                                                            }}
                                                        >
                                                            {t.name}
                                                        </a>
                                                    </div>
                                                );
                                            })}
                                        </ul>
                                        <a
                                            href="/about"
                                            class="block pl-4 pr-4 py-8 border-l-4 border-white-500 font-serif text-5xl focus:outline-none transition duration-150 ease-in-out"
                                            style={{
                                                fontWeight: "900",
                                                whiteWpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                color: "rgb(77, 77, 77)",
                                                fontSize: "4rem",
                                            }}
                                        >
                                            About
                                        </a>
                                        <a
                                            href="/submit"
                                            class="block pl-4 pr-4 py-8 border-l-4 border-white-500 font-serif text-5xl focus:outline-none transition duration-150 ease-in-out"
                                            style={{
                                                fontWeight: "900",
                                                whiteWpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                color: "rgb(77, 77, 77)",
                                                fontSize: "4rem",
                                            }}
                                        >
                                            Submit
                                        </a>
                                        {/* <a
                                            href="/order"
                                            class="block pl-4 pr-4 py-8 border-l-4 border-white-500 font-serif text-5xl focus:outline-none transition duration-150 ease-in-out"
                                            style={{
                                                fontWeight: "900",
                                                whiteWpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                color: "rgb(77, 77, 77)",
                                                fontSize: "4rem",
                                            }}
                                        >
                                            Order
                                        </a> */}
                                        <a
                                            href="https://thedial.us"
                                            class="block pl-4 pr-4 py-8 border-l-4 border-white-500 font-serif text-5xl focus:outline-none transition duration-150 ease-in-out"
                                            style={{
                                                fontWeight: "900",
                                                whiteWpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                color: "rgb(77, 77, 77)",
                                                fontSize: "4rem",
                                            }}
                                        >
                                            The Dial
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 flex items-center nav-logo-container">
                            <div>
                            <img
                                    className="sm:block h-full w-auto cursor-pointer"
                                    src={SymposeumDT}
                                    style={{'-webkit-transform': 'translate3d(0,0,0)}', height: '8rem'}}
                                    alt="Workflow logo"
                                    onClick={() => navigate("/")}
                                />
                                </div>
                        </div>
                        <div
                            style={{
                                position: "absolute",
                                right: 0,
                                height: "100%",
                                marginRight: 24
                            }}
                            className="nav-navigation-container"
                        >
                            <NavDesktopItem copy={"ISSUES"} link={"/issues"} classes={"collapse-800"}/>
                            <NavDesktopItem copy={"SUBMIT"} link={"/submit"} classes={"collapse-800"}/>
                            {/* <NavDesktopItem copy={"ORDER"} link={"/order"} classes={"collapse-600"} /> */}
                            <NavDesktopItem copy={"ABOUT"} link={"/about"}/>
                            <NavDesktopItem copy={"NEWSLETTER"} onClick={() => setShowSignUp(true)}/>

                        </div>
                    </div>
                </div>
                <div className="hidden sm:hidden">
                    <div className="pt-2 pb-4">
                        <a
                            href="#"
                            className="block pl-3 pr-4 py-2 border-l-4 border-white-500 text-base font-medium text-white-700 bg-white-50 focus:outline-none focus:text-white-800 focus:bg-white-100 focus:border-white-700 transition duration-150 ease-in-out"
                        >
                            Dashboard
                        </a>
                        <a
                            href="#"
                            className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
                        >
                            Team
                        </a>
                        <a
                            href="#"
                            className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
                        >
                            Projects
                        </a>
                        <a
                            href="#"
                            className="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
                        >
                            Calendar
                        </a>
                    </div>
                </div>
            </nav>
            <Modal setShowSignUp={setShowSignUp} showSignUp={showSignUp} />
        </>
    );
};

Navigation.defaultProps = {
    navclassNameName: `site-nav-item`,
};

Navigation.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    navclassNameName: PropTypes.string,
};

export default Navigation;
