import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Tags } from "@tryghost/helpers-gatsby";
import { readingTime as readingTimeHelper } from "@tryghost/helpers";
import { getNPTH, getMiscTag } from "../../utils";
import { useState } from "react";
const { detect } = require("detect-browser");
const browser = detect();

const safari = browser && browser.name === "safari";

// const clamp = {
//     overflow: 'hidden',
//     textOverflow: 'ellipsis',
//     display: '-webkit-box',
//     '-webkit-line-clamp': '5',
//     '-webkit-box-orient': 'vertical'
//                     // height: '60%'
// }

// const clean = (str) => {
//     const parser = new DOMParser();
//     const dom = parser.parseFromString(str.replace('Your browser does not support the audio element. ', '').replace('Reading by the author', ''), 'text/html');
//     const e = dom.getElementById("excerpt");
//     return e.innerHTML;
// }

const PostCard = ({ post }) => {
    const [style, setStyle] = useState(
        post && post.slug === "letter" ? { opacity: 1 } : {}
    );
    const url = `/${post.slug}/`;
    const readingTime = readingTimeHelper(post);

    const npth = getNPTH(post);
    const tag = getMiscTag(post);

    const title = post.title.split(":")[0];
    const sub = post.title.split(":")[1];

    const wrapperRef = useRef(null);

    // console.log(post.html.slice(0, 80));
    let text =
        post.plaintext &&
        post.plaintext
            .replace("Your browser does not support the audio element. ", "")
            .replace("Reading by the author", "");

    // const initDropCap = post.html
    //     .slice(0, 80)
    //     .includes('<span class="dropcap">');

    // if (initDropCap) {
    //     console.log(text[0]);
    //     const match = text.match(/[a-zA-Z]/);
    //     if (match) {
    //         text =
    //             text.slice(match.index, match.index + 1) +
    //             text.slice(match.index + 1, text.lengnth);
    //     }
    // }
    return (
        <Link
            to={url}
            className="post-card bg-white p-8 shadow-lg opacity-90 hover:opacity-100 transition duration-500 flex flex-col sm:h-90"
            style={style}
        >
            <header className="post-card-header">
                {/* {post.feature_image &&
                    <div className="post-card-image" style={{
                        backgroundImage: `url(${post.feature_image})` ,
                    }}></div>} */}
                {/* {post.tags && <div className="post-card-tags"> <Tags post={post} visibility="public" autolink={false} /></div>} */}
                {post.featured && <span>Featured</span>}
                <div>
                    <h2 className="font-serif post-card-title inline">
                        {title}
                    </h2>
                    {sub && (
                        <h2
                            className="font-serif font-weight flex flex-row font-light inline"
                            style={{ display: "inline" }}
                        >
                            {" "}
                            | {sub}
                        </h2>
                    )}
                </div>
            </header>
            <div className={`font-sans font-weight font-light mt-4`}>
                {post.authors.map((n) => n.name).join(", ")}{" "}
                {npth && `| ${npth.name}`} {tag && `| ${tag.name}`}
            </div>
            <section
                class={`hidden sm:flex font-serif overflow-hidden font-sans post-card-excerpt font-medium mt-4`}
                ref={wrapperRef}
                style={{
                    height: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexDirection: "column",
                }}
            >
                {text}
            </section>
            <section
                ref={wrapperRef}
                className="font-serif overflow-hidden font-sans post-card-excerpt post-card-excerpt-size font-medium mt-4 sm:hidden"
            >
                {text?.slice(0, 2000)}
            </section>
        </Link>
    );
};

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

export default PostCard;
